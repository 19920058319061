<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
      <div class="player listmain pcdd">
        <!-- 和值-->
        <div class="data ">
          <h3>和值</h3>
          <ul class="custom_ul">
            <!-- :class="item.selected ? 'selected' : ''" -->
            <li :class="item.selected?'selected':''"  @click="setCurrentSelected(item)" v-for="item in rowsData[0].arr" :key="item.ResultID">
              <ol class="td_name" style="width:35px">
                <!-- <div :class="pcddColors[Number(item.label)]+'ball1'">{{ item.label }}</div> -->
                {{ item.label }}
              </ol>
              <ol class="td_rate">
                <!-- item.Odds -->
                {{ item.Odds}}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>
          </ul>
        </div>
         
        <div class="data">
          <h3>跨</h3>
          <ul class="custom_ul h60">
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in rowsData[1].arr" :key="item.ResultID">
              <ol class="td_name" style="width:35px">
                {{item.label}}
              </ol>
              <ol class="td_rate">
                <!---->
                {{
                item.Odds 
                }}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>
        </div>
        <!-- <div class="data">
          <h3>百家乐</h3>
          <ul class="custom20">
            <li :class="item.selected?'selected':''" @click="setCurrentSelected(item)" v-for="item in rowsData[3].arr" :key="item.ResultID">
              <ol class="td_name" style="width:35px">
                {{item.label}}
              </ol>
              <ol class="td_rate">
              
                {{
                item.Odds 
                }}
              </ol>
              <ol class="td_cash">
                <input type="number" @click.stop="showOrHideCheck($event, item)" v-model="item.money" />
                <div class="quick-check" v-if="item.b">
                  <ul>
                    <li v-for="qs in quickSelectedData" :key="qs.num + '元'" @click.stop="setCurrentName(item, qs.num)">
                      下注{{ qs.num }}元
                    </li>

                    <li @click="closeCheck(item)">关闭</li>
                  </ul>
                </div>
              </ol>
            </li>

          </ul>
        </div> -->

      </div>
      <yushe />
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''" @click="setLongTab('1')">两面长龙排行</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="rc-tab-long" v-if="changelongActive=='1'">
          <div class="long-isbz">
            <span @click="setKaiTab('1')" :class="kaiActive == '1' ? 'selected' : ''">连续开奖</span>
            <span @click="setKaiTab('2')" :class="kaiActive == '2' ? 'selected' : ''">连续未开</span>
          </div>
          <ul class="longlist" v-if="kaiActive=='1'">
            <li v-for="item in rclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="red">{{item.num}}期</b>
            </li>
          </ul>
          <ul class="longlist" v-if="kaiActive=='2'">
            <li v-for="item in lclData" :key="item.ID">
              <span>{{item.info}}</span>
              <b class="blue">{{item.num}}期</b>
            </li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import { mapState } from "vuex";
import { zhengxiaoData } from "../data";
import mixins from "../mixins/mainMixins";
import yanzheng from "../yanzheng.vue";
export default {
  name: "",
  props: [""],
  mixins: [mixins],
  data() {
    return {
      groupnames:'zh,kua',
      changelongActive: "1",
      kaiActive: "1",
      rowsData: [
        { title: "和值", arr: [] },
        { title: "跨", arr: [] },
      ],
      // numArr3: [],
      // numArr2: [],
      // numArr1: [],
      num1: 0,
      num2: 1,
      num3: 2,
      numArr: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27,
      ],
      labelArr1: [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24, 25, 26, 27,
      ],
      labelArr2: [
        "大",
        "单",
        "极大",
        "大单",
        "大双",
        "小",
        "双",
        "极小",
        "小单",
        "小双",
      ],
      //大      单   极大  大单  大双   小     双   极小   小单  小双
      lmIds: [2048, 2050, 2052, 2054, 2055, 2049, 2051, 2053, 2056, 2057],
      labelArr3: ["红波", "绿波", "蓝波", "豹子",'顺子','对子'],
      colorArr: ["red", "green", "blue", ""],
      labelArr: ['0','1','2','3','4','5','6','7','8','9',
      '10','11','12','13','14','15','16','17','18','19',
      '20','21','22','23','24','25','26','27',
    ],
    };
  },
  computed: {
    numArr1() {
      return this.numArr.filter((num) => num != this.num2 && num != this.num3);
    },
    numArr2() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num3);
    },
    numArr3() {
      return this.numArr.filter((num) => num != this.num1 && num != this.num2);
    },
  },
  components: {
    topTimer,
    yushe,
    yanzheng,
  },
  watch: {
    num1: {
      immediate: true,
      handler(val) {},
    },
    // 判断是否可以点击确定提交按钮
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label,
                  Odds: ele.Odds,
                  title:  item.title,
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                // if (ele.label == "特码包三") {
                //   obj.num = this.num1 + "," + this.num2 + "," + this.num3;
                // }
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    // j监听数据
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
        //数字
        let idarr=[3674,5344,5345,5346,5347,5348,5349,3675,3676,3677,3678,3679,3680,3681,3682,3683,3684,3685,3686,3687,3688,3689,5350,5351,5352,5353,5354,5355]
        let arr1 = this.ddFilterData1(resAArr, idarr, this.labelArr);
        this.rowsData[0].arr = arr1;
        // 跨
       let labelArr30= ['0','1','2','3','4','5','6','7','8','9' ]
        let arr2 = this.filterData(resAArr, 3711, 3720, labelArr30);
        this.rowsData[1].arr = arr2;
 


        this.$forceUpdate();
      },
    },
  },
  created() {},

  mounted() {},

  methods: {
    setLongTab(n) {
      this.changelongActive = n;
    },
    setKaiTab(n) {
      this.kaiActive = n;
    },
  },
};
</script>
<style scoped>
.listmain > .data > ul.custom_ul.h60 li {
  width: 20%;
}
.listmain > .data > ul.custom33 li {
  width: 33.33%;
}
.listmain > .data ul.custom_ul li {
  height: 30px;
}
.listmain > .data ul.custom_ul.h60{
  height: 60px;
}
.listmain > .data ul.custom_ul {
  width: 100%;
  height: 210px;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  flex-flow: none;
}
.listmain .data ul li {
  width: 25%;
  height: 26px;
}
</style>
